import React, { useState } from "react"
import Layout from "../components/layout"
import SEO from "components/seo"
import SwiperCore, { Navigation, Scrollbar } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import "swiper/swiper.scss"
import "swiper/components/scrollbar/scrollbar.scss"
import { StaticImage } from "gatsby-plugin-image"
import constants from "../constants"
import RoundedBtn from "components/rounded-btn"
import ArrowIcon from "components/arrow-icon"

SwiperCore.use([Navigation, Scrollbar])

const collections = [
  {
    "title": "Spring/Summer<br/>2023 Collection",
    "subtitle": "Introducing",
    "btnTxt": "New Collection:<br/>Spring/Summer 2023",
    "roundBtn": "Discover Collection",
    "to": "/lookbook/ss23"
  },
  {
    "title": "Autumn/Winter<br/>2022 Collection",
    "subtitle": "Introducing",
    "btnTxt": "Previous Collection:<br/>Autumn/Winter 2022",
    "roundBtn": "Discover Collection",
    "to": "/lookbook/aw22"
  },
  {
    "title": "Spring/Summer<br/>2022 Collection",
    "subtitle": "Introducing",
    "btnTxt": "Previous Collection:<br/>Spring/Summer 2022",
    "roundBtn": "Discover Collection",
    "to": "/lookbook/ss22"
  },
/*
  {
    "title": "Season 2 Shorts<br/>Our Team Introducing SS22",
    "subtitle": "Current Collection",
    "btnTxt": "Season 2 Shorts<br/>Our Team Introducing SS22",
    "roundBtn": "Discover Shorts",
    "to": "/collage"
  },
*/
  {
    "title": "Autumn/Winter 2021<br/>November Drop",
    "subtitle": "Previous Collection",
    "btnTxt": "Previous Collection:<br/>Autumn/Winter 2021 November Drop",
    "roundBtn": "Discover Collection",
    "to": "/lookbook/aw21-drop"
  },
  {
    "title": "Autumn/Winter 2021<br/>September Drop",
    "subtitle": "Previous Collection",
    "btnTxt": "Previous Collection:<br/>Autumn/Winter 2021 September Drop",
    "roundBtn": "Discover Collection",
    "to": "/lookbook/aw21"
  },
]

for(let i=0; i < collections.length; i++) {
  collections[i].prevIndex = i == 0 ? undefined : i -1;
  collections[i].nextIndex = i == collections.length-1 ? 0 : i+1;
}

const HomePage = ({ location }) => {
  const [swiper, setSwiper] = useState(null)

  const images = [
    <StaticImage src={`../../static/home/ss23.jpg`} objectFit={`cover`} alt={``} className={`w-full h-full`} />,
    <StaticImage src={`../../static/home/aw22.jpg`} objectFit={`cover`} alt={``} className={`w-full h-full`} />,
    <StaticImage src={`../../static/home/ss22.jpg`} objectFit={`cover`} alt={``} className={`w-full h-full`} />,
    <StaticImage src={`../../static/home/aw21-drop.jpg`} objectFit={`cover`} alt={``} className={`w-full h-full`} />,
    <StaticImage src={`../../static/home/aw21.jpg`} objectFit={`cover`} alt={``} className={`w-full h-full`} />
  ]
  const imagesMobile = [
    <StaticImage src={`../../static/home/ss23-mobile.jpg`} objectFit={`cover`} alt={``} className={`w-full h-full`} />,
    <StaticImage src={`../../static/home/aw22-mobile.jpg`} objectFit={`cover`} alt={``} className={`w-full h-full`} />,
    <StaticImage src={`../../static/home/ss22-mobile.jpg`} objectFit={`cover`} alt={``} className={`w-full h-full`} />,
    <StaticImage src={`../../static/home/aw21-drop-mobile.jpg`} objectFit={`cover`} alt={``} className={`w-full h-full`} />,
    <StaticImage src={`../../static/home/aw21-mobile.jpg`} objectFit={`cover`} alt={``} className={`w-full h-full`} />
  ]

  const slideTo = (index) => {
    swiper.slideTo(index)
  }

  const allSlides = collections?.map((item, index) => {
    const nextCollection = collections[item?.nextIndex]
    const prevCollection = collections[item?.prevIndex]
    const textColor = "text-white"
    const borderColor = "!border-white"

    return (
      <SwiperSlide
        key={`${index}-image-slide`}
        className={`w-full h-full`}
      >
        <div className={`w-full h-full relative`}>
          <div className={`absolute w-full h-full z-20 ${textColor}`}>
            <div className={`text-center flex items-center justify-center w-full h-full relative`}>
              <div style={{ transform: `translateZ(2px)` }} className={`absolute z-10`}>
                <div className={`${constants.h4}`}>
                  {item.subtitle}
                </div>
                <div className={`${constants.h2} text-[38px]`} dangerouslySetInnerHTML={{ __html: item.title }} />
                <RoundedBtn
                  to={item.to}
                  className={`${borderColor} px-10 lg:px-12 py-4 flex mt-8 lg:mt-14 ${constants.button} inline-block`}
                >{item.roundBtn}</RoundedBtn>
              </div>
            </div>
            { item.prevIndex !== undefined &&
            <button style={{ outline: 0 }} onClick={() => { slideTo(item.prevIndex) }} className={`hidden lg:block absolute bottom-6 left-6 text-left ${constants.button} !leading-none`}>
              <div className={`flex flex-col space-y-2 leading-tight`}>
                <div dangerouslySetInnerHTML={{ __html: prevCollection.btnTxt }} />
                <ArrowIcon className={`transform rotate-180 ${textColor}`} />
              </div>
            </button>
            }
            { (item.nextIndex !== undefined && index !== collections.length - 1) &&
            <button style={{ outline: 0 }} onClick={() => { slideTo(item.nextIndex) }} className={`hidden lg:block absolute bottom-6 right-6 text-right ${constants.button} !leading-none`}>
              <div className={`flex flex-col items-end space-y-2 leading-tight`}>
                <div dangerouslySetInnerHTML={{ __html: nextCollection.btnTxt }} />
                <ArrowIcon className={`${textColor}`} />
              </div>
            </button>
            }
          </div>
          <div className={`hidden lg:block w-full h-full relative z-0 bg-black`}>{ images[index] }</div>
          <div className={`block lg:hidden w-full h-full relative z-0 bg-black`}>{ imagesMobile[index] }</div>
        </div>
      </SwiperSlide>
    )
  })

  return (
    <Layout location={location}>
      <SEO title={`Home`} pathname={location.pathname} />
      <div className={`w-full h-screen bg-black`}>
        <Swiper
          className={`w-full h-full mySwiper bg-black`}
          onSwiper={setSwiper}
          spaceBetween={0}
          slidesPerView={1}
          scrollbar={{
            el: '.index-swiper-scrollbar',
            dragClass: 'swiper-scrollbar-drag w-full h-full relative rounded-full left-0 top-0 bg-white',
            hide: false
          }}
        >
          <div className="swiper-wrapper bg-black">
            {allSlides}
          </div>
          <div style={{ backgroundColor: `rgba(255,255,255,.3)` }} className={`lg:hidden index-swiper-scrollbar absolute bottom-12 z-50 h-[4px] w-9/12 left-1/2 transform -translate-x-1/2 rounded-full`}></div>
        </Swiper>
      </div>
    </Layout>
  )
}

export default HomePage;
